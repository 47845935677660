<template>
    <div class="consultation-block">
        <div>
            <default-button size="large" class="consult-button" @click="showModal = true">{{ data.textButton }} </default-button>
        </div>
        <div class="phone-information">
            <span class="phone-text">{{ contentPagesStore.consultationTranslate.phoneText }}</span>
            <a :href="`tel:${phone}`" class="phone_link">{{ phone }}</a>
        </div>
        <ModalBlock v-model="showModal" :class="{ success: formSend && formSuccess, error: formSend && !formSuccess }">
            <ConsultationForm v-if="formSend === false" @update:form-success="changeStatus" />
            <div v-if="formSend" class="col-12 d-flex justify-content-center align-items-center">
                <ModalSuccess v-if="formSuccess" />
                <ModalError v-if="!formSuccess" @restart="formSend = false" />
            </div>
        </ModalBlock>
    </div>
</template>

<script setup lang="ts">
import DefaultButton from '~/components/Button/DefaultButton.vue';
import { useContentPages } from '~/stores/homeStores';
import ModalBlock from '~/components/layout/ModalBlock.vue';
import ConsultationForm from '~/pages/index/components/ConsultationForm.vue';
import ModalSuccess from '~/pages/index/components/ModalSuccess.vue';
import ModalError from '~/pages/index/components/ModalError.vue';

const showModal = ref(false);
const contentPagesStore = useContentPages();
const countryStore = useCountry();
const data = computed(() => contentPagesStore.consultationTranslate);
const phone = computed(() => countryStore?.currentCity?.tel_number || data.value.phone);
const formSend = ref(false);
const formSuccess = ref(false);
const changeStatus = (success: boolean) => {
    formSend.value = true;
    formSuccess.value = success;
};

watch(showModal, () => {
    formSend.value = false;
    formSuccess.value = false;
});
</script>

<style lang="scss" scoped>
.consult-button {
    font-weight: 700;
    font-size: 20px !important;
    line-height: 24px;
    padding: 20px 36px !important;
}

.consultation-block {
    gap: 1.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.phone-information {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.phone-text {
    color: var(--main-black-2-b-2-b-2-b, #2b2b2b);
    /* secondary/regular/16-20 */
    font-family: Roboto;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem; /* 125% */
}

.phone_link {
    color: var(--other-blue-48538-b, #48538b);
    font-family: Roboto;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2rem;
    text-decoration: none;
}
</style>
